<template>
  <div ref="BarChartRef" class="chart-container">

  </div>
</template>

<script>
  import $echartsConfig from '@/utils/echartsConfig';
  export default {
    name: 'PieChart',
    props: {
      isAxisChange: {
        type: Boolean,
        default: false
      },
      optionsData: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        echartsView: null,
        pieOption: null,
      }
    },
    watch: {
      optionsData: {
        // immediate: true,
        deep: true,
        handler() {
          this.updateChartOption();
        }
      }
    },
    mounted () {
      this.initChart();
    },
    methods: {
      initChart() {
        this.echartsView = $echartsConfig.echarts.init(this.$refs.BarChartRef);
        this.initChartOption();
      },
      initChartOption() {
        this.pieOption = {
          title: '',
          tooltip: {
            trigger: 'item'
          },
          grid: {
            ...$echartsConfig.grid,
            right: 30,
          },
          legend: {
            icon: 'circle',
            orient: 'vertical',
            top: 'middle',
            right: 0,
            formatter: (name) => {
              let total = 0
              let tarValue = 0;
              const { seriesData } = this.optionsData; 
              for (let i = 0; i < seriesData.length; i++) {
                total += seriesData[i].value
                if (seriesData[i].name == name) {
                  tarValue = seriesData[i].value
                }
              }
              // let v = tarValue + 'G';
              //计算出百分比
              let p = ((tarValue / total) * 100).toFixed(2) + '%'  
              return `${name}  ${p}`
              //name是名称，v是数值
            }
          },
          series: [
            {
              name: '业务类型申报量',
              type: 'pie',
              radius: ['35%', '70%'],
              center: [ '35%', '45%' ],
              label: {
                show: true,
                // alignTo: 'edge',
                minMargin: 15,
                edgeDistance: 20,
                lineHeight: 15,
                formatter(param) {
                  return `${param.name}\n ${param.percent}%`;
                }
              },
              labelLine: {
                length: 5,
                length2: 15,
                maxSurfaceAngle: 80
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              },
              data: [],
            },
          ],
        };
        this.echartsView.setOption(this.pieOption);
      },
      updateChartOption(){
        const { seriesData } = this.optionsData;
        let option = {
          series: [
            {
              data: seriesData,
            }
          ]
        };
        this.echartsView.setOption(option, false);
      }
    },
  }
</script>


<style lang="scss" scoped>

</style>