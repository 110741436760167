<template>
  <div class="TopN">
    <div class="TopN-num">
      <div class="statistics_title">申报关区占比Top10-<span class="sub_title">单量</span></div>
      <el-row :gutter="40">
        <el-col :span="6">
          <div class="chart-title">业务类型：<span>9610</span></div>
          <BarChart
            :optionsData="businessOptions['9610']"

          />
        </el-col>
        <el-col :span="6">
          <div class="chart-title">业务类型：<span>9710</span></div>
          <BarChart
            :optionsData="businessOptions['9710']"
          />
        </el-col>
        <el-col :span="6">
          <div class="chart-title">业务类型：<span>9810</span></div>
          <BarChart
            :optionsData="businessOptions['9810']"
          />
        </el-col>
        <el-col :span="6">
          <div class="chart-title">业务类型：<span>1210</span></div>
          <BarChart
            :optionsData="businessOptions['1210']"
          />
        </el-col>
      </el-row>
    </div>
    <div class="TopN-price">
      <div class="statistics_title">申报关区占比Top10-<span class="sub_title">金额</span></div>
      <el-select class="search" size="small" v-model="currency">
        <el-option
          v-for="item in currencyList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-row :gutter="40">
        <el-col :span="6">
          <div class="chart-title">业务类型：<span>9610</span></div>
          <BarChart
            :optionsData="priceOptions['9610']"
          />
        </el-col>
        <el-col :span="6">
          <div class="chart-title">业务类型：<span>9710</span></div>
          <BarChart
            :optionsData="priceOptions['9710']"
          />
        </el-col>
        <el-col :span="6">
          <div class="chart-title">业务类型：<span>9810</span></div>
          <BarChart
            :optionsData="priceOptions['9810']"
          />
        </el-col>
        <el-col :span="6">
          <div class="chart-title">业务类型：<span>1210</span></div>
          <BarChart
            :optionsData="priceOptions['1210']"
          />
        </el-col>
      </el-row>
    </div>
    <div class="TopN-piechart">
      <div class="statistics_title">申报关区占比TOP10</div>
      <PieChart
        :optionsData="pieOptions"
      />
    </div>
    <!-- <el-row>
      <el-col :span="16">
        <div class="statistics-container">
          <div class="statistics-container-item">
            <div class="statistics_title">申报关区占比Top10-<span class="sub_title">单量</span></div>
            <BarChart
              :optionsData="RMBOptions"
            />
          </div>
          <div class="statistics-container-item">
            <div class="statistics_title">
              申报关区占比Top10-<span class="sub_title">金额</span>
            </div>
            <el-select class="search" size="small" v-model="currency">
              <el-option
                v-for="item in currencyList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <BarChart
              :optionsData="USDOptions"
            />
          </div>
        </div>
      </el-col>
      <el-col class="TopN-chart" :span="8">
        <PieChart
          :optionsData="pieOptions"
        />
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
  import BarChart from '../BarChart.vue';
  import PieChart from '../PieChart.vue';
  import { mapState } from 'vuex';
  export default {
    name: 'TopN',
    components: {
      BarChart,
      PieChart,
    },
    props: {
      queryParams: {
        type: Object,
        required: true, 
      },
    },
    data() {
      return {
        params: {
          timeTag: '',
          startDate: '',
          endDate: '',
        },
        businessOptions: {
          9610: {
            xData: [],
            axisValueName: "单",
            seriesData: []
          },
          9710: {
            xData: [],
            axisValueName: "单",
            seriesData: []
          },
          9810: {
            xData: [],
            axisValueName: "单",
            seriesData: []
          },
          1210: {
            xData: [],
            axisValueName: "单",
            seriesData: []
          }
        },
        priceOptions: {
          9610: {
            xData: [],
            axisValueName: "万元",
            seriesData: []
          },
          9710: {
            xData: [],
            axisValueName: "万元",
            seriesData: []
          },
          9810: {
            xData: [],
            axisValueName: "万元",
            seriesData: []
          },
          1210: {
            xData: [],
            axisValueName: "万元",
            seriesData: []
          }
        },
        pieOptions: {
          seriesData: [],
        },
        currency: '美元',
        currencyList: [
          { label: '美元', value: '美元' },
          { label: '日元', value: '日本元' },
          { label: '人民币', value: '人民币' },
        ]
      }
    },
    computed: {
      ...mapState([
        'serviceModeEnums'
      ])
    },
    watch: {
      queryParams: {
        handler(params) {
          this.setDeclareReset();
          this.setAmountReset();
          this.setRatioReset();
          this.params = params;
          this.getDeclareData();
          this.getAmountData();
          this.geRatiotData();
        }
      },
      currency: {
        handler() {
          this.setAmountReset();
          this.getAmountData();
        }
      }
    },
    mounted () {
      // this.getData();
    },
    methods: {
      async getDeclareData() {
        const res9610 = await this.$request.get('/data/portBusinessData/declareTotalNum', { params: {
          ...this.params,
          tradeMode: '9610',
          current: 1,
          size: 100
        } });
        if(res9610.data && res9610.data.length) {
          res9610.data.forEach(item => {
            const { tradeMode, portName, num } = item;
            if(this.businessOptions[tradeMode].xData.length < 11) {
              this.businessOptions[tradeMode].xData.push(portName);
              this.businessOptions[tradeMode].seriesData.push(num);
            }
          });
        }
        const res9710 = await this.$request.get('/data/portBusinessData/declareTotalNum', { params: {
          ...this.params,
          tradeMode: '9710',
          current: 1,
          size: 100
        } });
        if(res9710.data && res9710.data.length) {
          res9710.data.forEach(item => {
            const { tradeMode, portName, num } = item;
            if(this.businessOptions[tradeMode].xData.length < 11) {
              this.businessOptions[tradeMode].xData.push(portName);
              this.businessOptions[tradeMode].seriesData.push(num);
            }
          });
        }
        const res9810 = await this.$request.get('/data/portBusinessData/declareTotalNum', { params: {
          ...this.params,
          tradeMode: '9810',
          current: 1,
          size: 100
        } });
        if(res9810.data && res9810.data.length) {
          res9810.data.forEach(item => {
            const { tradeMode, portName, num } = item;
            if(this.businessOptions[tradeMode].xData.length < 11) {
              this.businessOptions[tradeMode].xData.push(portName);
              this.businessOptions[tradeMode].seriesData.push(num);
            }
          });
        }
        const res1210 = await this.$request.get('/data/portBusinessData/declareTotalNum', { params: {
          ...this.params,
          tradeMode: '1210',
          current: 1,
          size: 100
        } });
        if(res1210.data && res1210.data.length) {
          res1210.data.forEach(item => {
            const { tradeMode, portName, num } = item;
            if(this.businessOptions[tradeMode].xData.length < 11) {
              this.businessOptions[tradeMode].xData.push(portName);
              this.businessOptions[tradeMode].seriesData.push(num);
            }
          });
        }
      },
      async getAmountData() {
        const resAmount = await this.$request.get('/data/portBusinessData/tradeAmount', { params: {
          ...this.params,
          currency: this.currency,
          current: 1,
          size: 100
        } });
        if(resAmount.data) {
          const currencyData = Object.values(resAmount.data)
          .reduce((prev, cur) => {
            prev = [...prev, ...cur];
            return prev;
          }, []);
          currencyData.forEach(item => {
            const { tradeMode, portName, totalPrice } = item;
            this.priceOptions[tradeMode]?.xData.push(portName);
            this.priceOptions[tradeMode]?.seriesData.push((totalPrice / 10000).toFixed(3));
          });
          // Object.keys(resAmount.data).forEach(name => {
          //   const data = resAmount.data[name].filter(item => item.currency === this.currency);
          //   data.forEach(item => {
          //   const { tradeMode, portName, totalPrice } = item;
          //     this.priceOptions[tradeMode].xData.push(portName);
          //     this.priceOptions[tradeMode].seriesData.push((totalPrice / 10000).toFixed(3));
          //   })
          // })
        }
      },
      async geRatiotData(params) {
        // 单量占比
        const resRatio = await this.$request.get('/data/portBusinessData/ratio', { params });
        if(resRatio.data && resRatio.data.length) {
          this.pieOptions.seriesData = resRatio.data.map(item => ({
            name: item.portName,
            value: item.num,
          }));
        }
      },
      setDeclareReset() {
        this.businessOptions = {
          9610: {
            xData: [],
            axisValueName: "单",
            seriesData: []
          },
          9710: {
            xData: [],
            axisValueName: "单",
            seriesData: []
          },
          9810: {
            xData: [],
            axisValueName: "单",
            seriesData: []
          },
          1210: {
            xData: [],
            axisValueName: "单",
            seriesData: []
          }
        }
      },
      setAmountReset() {
        this.priceOptions= {
          9610: {
            xData: [],
            axisValueName: "万元",
            seriesData: []
          },
          9710: {
            xData: [],
            axisValueName: "万元",
            seriesData: []
          },
          9810: {
            xData: [],
            axisValueName: "万元",
            seriesData: []
          },
          1210: {
            xData: [],
            axisValueName: "万元",
            seriesData: []
          }
        }
      },
      setRatioReset() {
        this.pieOptions= {
          seriesData: [],
        }
      }
    },
  }
</script>

<style lang="scss" scoped>

.TopN{
  &-num,
  &-price{
    height: 300px;
    padding: 24px 24px;
    background-color: #fff;
    box-sizing: border-box;
    .el-row{
      height: 100%;
      .el-col{
        height: 100%;
        .chart-title{
          padding-right: 20px;
          margin-top: 20px;
          color: #6C7293;
          text-align: right;
          span{
            color: #3484FB;
          }
        }
        .chart-container{
          height: calc(100% - 40px);
        }
      }
    }
  }
  &-price{
    margin: 24px 0;
    position: relative;
    .search{
      width: 100px;
      position: absolute;
      top: 20px;
      right: 24px;
    }
  }
  &-piechart{
    width: 100%;
    height: 300px;
    padding: 24px 24px;
    background-color: #fff;
    box-sizing: border-box;
    .chart-container{
      height: calc(100% - 20px);
      padding: 0 24px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  // .el-row{
  //   width: 100%;
  //   height: 100%;
  //   .el-col{
  //     height: 100%;
  //   }
  // }
  // &-chart{
  //   padding-left: 24px;
  //   .chart-container{
  //     padding: 24px 24px;
  //     background-color: #fff;
  //     box-sizing: border-box;
  //   }
  // }
}
</style>