<template>
  <div ref="BarChartRef" class="chart-container">

  </div>
</template>

<script>
  import $echartsConfig from '@/utils/echartsConfig';
  export default {
    name: 'BarChart',
    props: {
      type: {
        type: String,
        default: 'bar',
      },
      isAxisChange: {
        type: Boolean,
        default: false
      },
      optionsData: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        echartsView: null,
        barOption: null,
      }
    },
    watch: {
      optionsData: {
        // immediate: true,
        deep: true,
        handler() {
          this.updateChartOption();
        }
      }
    },
    mounted () {
      this.initChart();
    },
    methods: {
      initChart() {
        this.echartsView = $echartsConfig.echarts.init(this.$refs.BarChartRef);
        // this.initChartOption();
        this.initEmptyChart();
      },
      initEmptyChart() {
        const emptyOption = {
          title: {
            text: "暂无数据",
            x: "center",
            y: "center",
            textStyle: {
              color: "#333",
              fontWeight: "normal",
              fontSize: 16,
            },
          },
        };
        this.echartsView.setOption(emptyOption);
      },
      initChartOption() {
        this.barOption = {
          title: {
            show: false,
          },
          tooltip: $echartsConfig.tooltip,
          grid: {
            ...$echartsConfig.grid,
            top: this.optionsData.title ? 50 : 40,
            right: 30,
            left: 10,
            bottom: 10
          },
          xAxis: {
            type: 'value',
            show: true,
          },
          yAxis: {
            ...$echartsConfig.axis,
            type: 'category',
            // inverse: true,
            data: [],
            
          },
          series: [
            {
              type: this.type,
              barWidth: 20,
              yAxisIndex: 0,
              itemStyle: {
                borderRadius: [4, 4, 4, 4],
                color: (param) => {
                  return param.value > 0 ? '#FE7C4B' : '#23B899';
                }
              },
              // label: {
              //   show: false,
              //   position: 'right',
              //   color: '#33333B',
              //   formatter: '{c}%',
              // },
              data: [],
            },
          ],
        };
        this.echartsView.setOption(this.barOption);
        $echartsConfig.onresize(this.$refs.BarChartRef)
      },
      updateChartOption(){
        this.echartsView.clear();
        const { axisValueName, xData, seriesData } = this.optionsData;
        if(seriesData.length) {
          this.initChartOption();
        } else {
          this.initEmptyChart();
          return false;
        }
        let option = {};
        if(this.isAxisChange) {
          option = {
            yAxis: {
              data: xData,
            },
            series: [
              {
                data: seriesData,
              }
            ]
          }
        } else {
          option = {
            xAxis: {
              ...$echartsConfig.axis,
              type: 'category',
              data: xData,
              axisLabel: {
                color: '#6C7293',
                rotate: 45,
                formatter: function(value) {
                  // return value.length > 4 ? `${value.slice(0, 4)}\n${value.slice(4, value.length)}` : value;
                  return value.length > 4 ? `${value.slice(0, 4)}...` : value;
                }
              },
              axisLine: {
                lineStyle: {
                  width: 0.8,
                  type: 'solid',
                  color: '#EAEBF0',
                },
              },
            },
            yAxis: {
              type: 'value',
              name: axisValueName || '',
              show: true,
              axisLabel: {
                color: '#8590B3',
              },
              splitLine: {
                lineStyle: {
                  width: 0.8,
                  type: 'solid',
                  color: '#EAEBF0',
                },
              },
            },
            series: [
              {
                data: seriesData,
              }
            ]
          }
        }
        this.echartsView.setOption(option, false);
      },
      getFormatOptions(data) {
        let xData = data.map(item => item.name);
        let legendData = Object.values(this.legend);
        let seriesData = Object.keys(this.legend).map(name => {
          return data.map(item => item[name]);
        });
        return {
          legendData,
          xData,
          seriesData
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.chart-container{
  width: 100%;
  height: 100%;
}
</style>